import React, { lazy, Suspense } from "react";
import NavbarComponent from "./Navbar";
import styled, { createGlobalStyle } from "styled-components";
import { Container } from "react-bootstrap";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const ComplaintsContainer = styled(Container)`
  margin-top: 300px;
  margin-bottom: 100px;
`;

const Complaints_policy = () => {
  return (
    <div>
      <GlobalStyle />
      <NavbarComponent />

      <ComplaintsContainer>
        <h1>Complaints Policy</h1>

        <h2>1. Complaints</h2>
        <p>
          Retail Park Ltd takes complaints seriously as they help us to improve
          areas of our products and services and to resolve any issues relating
          to the consistency and quality of our business operations. Our
          complaints policy is an opportunity for customers to tell us when we
          might have fallen short of expectation, and how we can put things
          right. Retail Park Ltd receive, evaluate, make and record its
          decisions on complaints in a non-discriminatory manner, in accordance
          with the requirements of the accredited parking association.
        </p>

        <h2>2. Policy Scope</h2>
        <p>
          The policy is designed to deal with concerns raised in relation to
          parking enforcement only; complaints that do not relate to matters
          pertaining to the BPA’s Approved Operator Code of Practice or Parking
          (Code of Practice) Act 2019 are not covered under the scope of the
          policy.
        </p>

        <h2>3. Definition of a Complaint</h2>
        <p>
          The complaints policy is not intended to be used as a method for
          motorists to appeal a Parking Charge Notice (PCN) or Notice of Parking
          Charge (NPC). Matters relating specifically to appeals must be made in
          writing as instructed on the notice itself. If a complaint is received
          that is considered to be or includes an appeal against the validity of
          an PCN or NPC, we will treat it as an appeal and advise the customer
          of this, unless we are informed that the customer does not wish it to
          be so handled. Our definition of a complaint is something about the
          quality of the service provided by an organisation, its processes
          and/or the behaviour of its staff. Our definition of an appeal –
          correspondence shared against the decision of an organisation – in
          this instance, the decision to issue a Parking Charge Notice or Notice
          of Parking Charge – where a change to that decision is required.
        </p>

        <h2>4. How to make a Complaint</h2>
        <p>
          Customers who wish to make a complaint must do so in writing. This is
          to ensure we know exactly what the nature of the complaint is and this
          reduces the possibility of ambiguity or of the customer’s complaint
          not being correctly recorded over the telephone. The complaint will
          then be registered onto our system and a unique reference code
          generated. Once the complaint has been received, we will acknowledge
          the complaint within 14 days and provide the unique reference code.
          The acknowledgement will be sent to the name and address or email
          provided. In the absence of valid contact details, it may not be
          possible to process a complaint or process it within the published
          timeframes.
        </p>

        <p>
          We will respond to complaints within 28 days of receipt. In some
          cases, however, the allotted timeframe could be extended due to the
          nature of the complaint and the complexities surrounding any
          investigation. If we are unable to reply to the complaint within 28
          days, the customer will be written to, to advise of progress. A
          complaint must be made in writing via email or post:
        </p>
        <p>Email: enquiries@retailparkuk.co.uk</p>
        <p>Postal Address:Retail Park Ltd, PO Box 6142, SHEFFIELD, S2 9JA</p>
        <p>
          The complaint must be made within 56 days of the incident taking
          place.
        </p>

        <h2>5. How Complaints will be recorded</h2>
        <p>
          Complaints will be recorded on a complaint’s register and kept on file
          for 36 months and these will be available on request to authorised
          bodies. The details that will be retained will be:
        </p>
        <ul>
          <li>Date of complaint</li>
          <li>Copy of complaint</li>
          <li>Copy of all correspondence</li>
          <li>The outcome</li>
          <li>Details of any corrective action required</li>
        </ul>
        <p>
          All personal data will be redacted in line with GDPR requirements. The
          complaints register will be reviewed every 3 Months to identify trends
          and training opportunities.
        </p>

        <h2>6. Escalation Process</h2>
        <h3>6.1 Stage one</h3>
        <p>
          In the event that the customer is not satisfied with the handling of
          the complaint, the complaint can be escalated to Jessica Coward. The
          HR Manager will acknowledge the escalated complaint within 14 days. A
          full response to your complaint will be issued within 28 days unless
          exceptional circumstances have been identified. If more time is
          needed, the customer will be written to with an update.
        </p>

        <h3>6.2 Stage two</h3>
        <p>
          If the customer remains dissatisfied with our determination of the
          complaint, we will provide you with the details to enable you to
          complain to our Accredited Trade Association or Conformity Assessment
          Body (full details will be provided at the appropriate time). In order
          to escalate a complaint to our Accredited Trade Association or
          Conformity Assessment Body, the customer must supply our Accredited
          Trade Association or Conformity Assessment Body with a copy of our
          final complaint response. Our Accredited Trade Association or
          Conformity Assessment Body will not review escalated complaints where
          this is not provided by the customer.
        </p>

        <h2>7. Confidentiality</h2>
        <p>
          All complaints will be dealt with in accordance with the requirements
          of the Data Protection Act 2018. Please note, when a complaint
          concerns the issuing of an NPC issued by us, Retail Park Ltd are the
          data controller. As such the customer should be aware that any
          information provided in connection with the complaint will be used by
          Retail Park Ltd to help us deal with it. The customer’s information
          may also be passed to Retail Park Ltd staff who were enforcing any
          parking restrictions or conditions at the relevant site. Information
          may also be shared with the landowner and any permit service provider
          if relevant to allowing the complaint to be investigated and resolved.
          For more information on how we use your information you can contact
          our data protection officer Kirstie O’Connor. More information about
          your rights concerning the use of your personal data is available
          within our privacy policy found on our website www.retailparkuk.co.uk
        </p>
      </ComplaintsContainer>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
};

export default Complaints_policy;

import React, { useState, lazy, Suspense } from "react";
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-scroll";
import NavbarComponent from "./Navbar";
import styled, { createGlobalStyle } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faParking,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

import logo from "./assets/images/logo.png";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  }
`;
const FooterInsert = lazy(() => import("./FooterInsert"));

const StyledLink = styled(Link)`
  position: relative;
  color: black;
  margin-right: 50px;
  text-decoration: none;
  font-size: 18px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -10px;
    left: 0;
    background-color: transparent;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    visibility: visible;
    width: 100%;
    background-color: transparent;
  }
`;

const Logo = styled.img`
  height: 200px;
  width: auto;
  border: none;
  outline: none;
  text-decoration: none !important;
  z-index: 1;
`;

const ContactContainer = styled(Container)`
  margin-top: 50px;
  margin-bottom: 100px; // adjust this value according to your needs
`;

const StyledForm = styled(Form)`
  .form-control {
    height: 50px;
    font-size: 20px;
    border: 2px solid #333;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: #007bff; // Adding hover/focus effect to form fields
    }
  }

  .form-control[type="textarea"] {
    height: 150px;
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  margin-bottom: 30px;
  width: 120%; // Increasing the width of the cards
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); // Adding hover effect to cards
  }
`;

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // Initialize navigate from the useNavigate hook
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send a POST request to your server
    fetch("https://www.retailparkuk.co.uk/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // Convert the React state to JSON and send it as the POST body
      body: JSON.stringify({ name, email, message, recaptchaValue }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          resetForm();
          navigate("/thankyou"); // Redirects to Thank You page
        } else if (data.status === "fail") {
          alert("Message failed to send.");
        }
      });
  };

  // function to reset the form
  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setRecaptchaValue(null);
  };

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Get in touch with us via our contact form or find contact details for specific departments."
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />
      <Container>
        <Row>
          <Col>
            <StyledLink
              to="/"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <Logo src={logo} alt="Logo" />
            </StyledLink>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row className="d-flex justify-content-between">
          <Col md={3}>
            <StyledCard>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faEnvelope} /> Sales Enquiries
                </Card.Title>
                <Card.Text>
                  For sales enquiries:{" "}
                  <a href="mailto:sales@retailparkuk.co.uk">
                    sales@retailparkuk.co.uk
                  </a>
                </Card.Text>
              </Card.Body>
            </StyledCard>
          </Col>
          <Col md={3}>
            <StyledCard>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faParking} /> PCN (Parking Charge
                  Notice)
                </Card.Title>
                <Card.Text>
                  For PCN:{" "}
                  <a href="mailto:pcn@retailparkuk.co.uk">
                    pcn@retailparkuk.co.uk
                  </a>
                  <br />
                  <Button variant="primary" href="/appeals_process">
                    Full Appeals Process
                  </Button>
                </Card.Text>
              </Card.Body>
            </StyledCard>
          </Col>
          <Col md={3}>
            <StyledCard>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faQuestion} /> General Enquiries
                </Card.Title>
                <Card.Text>
                  For general enquiries:{" "}
                  <a href="mailto:enquiries@retailparkuk.co.uk">
                    enquiries@retailparkuk.co.uk
                  </a>
                </Card.Text>
              </Card.Body>
            </StyledCard>
          </Col>
        </Row>
      </Container>

      <ContactContainer>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <h2>Contact Us</h2>
              <StyledForm onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </Form.Group>
                <ReCAPTCHA
                  sitekey="6LfQwP4mAAAAAFNrpEzjt_WlgoVgrYAh7ORlVqgT"
                  onChange={handleRecaptcha}
                />
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </StyledForm>
            </Col>
          </Row>
        </Container>
      </ContactContainer>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
};

export default Contact;

import React, { lazy, Suspense } from 'react';
import NavbarComponent from './Navbar';
import styled, { createGlobalStyle } from 'styled-components';
import { Container } from 'react-bootstrap';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  }
`;

const FooterInsert = lazy(() => import('./FooterInsert'));

const PrivacyContainer = styled(Container)`
  margin-top: 300px;
  margin-bottom: 100px;
`;

const Privacy_policy = () => {
  return (
    <div>
      <GlobalStyle />
      <NavbarComponent />
      
      <PrivacyContainer>
        <h1>Privacy Notice – Visitors to ANPR Car Parks</h1>

        <p>Retail Park Limited (12782256) Registered Address – PO Box 6142, Sheffield, S2 9JA. Registered in England and Wales Data Protection Officer Contact details – dataprotection@retailpark.co.uk Phone Number – 0114 3494558 Entry into the car park may result in your personal data being obtained, retained and processed. The purpose of processing this data is for the performance of a contract and enforcement of a contract including to:</p>
        
        <ul>
            <li>Identify vehicles that are in breach of terms and conditions</li>
            <li>Enforce parking restrictions</li>
            <li>Issue and enforce parking charge notices</li>
        </ul>
        
        <p>Data may be processed off site by a third party for enforcement purposes. Your data will be kept for a maximum of 6 years. Your data will be processed in line with the General Data Protection Regulation.</p>
        
        <h1>Privacy Notice – Received a Notice to Keeper</h1>

        <p>Retail Park Limited (12782256)Registered address – PO Box 6142, Sheffield, S2 9JA.. Registered in England and Wales Data Protection Officer contact details – dataprotection@retailpark.co.uk Phone Number 0114 3494558 If you have received a Notice to Keeper from us this means that the vehicle has been recorded via an ANPR camera and/or parking attendant contravening the terms and conditions of the car park.</p>

        <p>The purpose of processing this data is for the performance of a contract and enforcement of a contract. Your data may be processed by the following third parties for the purposes of debt recovery and enforcement:</p>
        
        <ul>
            <li>Solicitors</li>
            <li>Debt recovery agencies</li>
            <li>Payment processing agencies</li>
        </ul>

        <p>Your data will be kept for a maximum of 6 years. Your data will be processed in line with the General Data Protection Regulation. For more information on how we process your data please visit: www.retailpark.co.uk</p>

      </PrivacyContainer>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
};

export default Privacy_policy;

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import TwinTerminalImage from "./assets/images/MK2-1.png";
import TwinChargeImage from "./assets/images/twinmk3.PNG"
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 200px;
`;

const SectionTwo = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageTwo = styled.img`
  width: 100%;
  max-width: 300px; // Adjust this value to your preferred size
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  margin-left: 20px; 
  &:hover {
    transform: scale(1.05);
  }
`;



const CardPaymentPage = () => {
  return (
    <div>
      <Helmet>
        <title>Card Payment Terminal - Retail Park Limited</title>
        <meta
          name="description"
          content="Retail Park Limited has partnered with Twin to offer intelligent, cashless parking and charging solutions with their ultra-modern payment terminals. Future-proof your car park with Twin's flexible solutions."
        />
        <meta
          name="keywords"
          content="Card Payment Terminal, Retail Park, Twin, car parking, cashless parking, EV charging, future-proof car park, contactless payment"
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />
      <Section>
        <Container>
          <Row>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="right">
                <Heading>Card Payment Terminal</Heading>
                <Text>
                  Retail Park Ltd is pleased to announce our partnership with
                  Twin to provide intelligent, cashless parking and charging
                  solutions. Twin's payment terminals are ultra-modern, with
                  easy-to-navigate touchscreens, clear instructions, and
                  convenient contactless payment options.
                </Text>
                <Text>
                  Twin offers a range of durable and reliable wall or floor
                  mounted versions suitable for both internal and external use,
                  ensuring we have your environment covered. These terminals
                  offer a modular combination or singular action of paying for
                  parking, charging, or both at the same time.
                </Text>
                <Text>
                  Customisation options are available for both the body of the
                  terminal and the touchscreen, aligning with your brand for
                  maximum impact. All terminals are preconfigured to specific
                  requirements for immediate site setup, including initial
                  tariff which can be altered remotely and instantly via Twin's
                  back office function. Twin's back office also provides a range
                  of real-time data and tools to improve operations and customer
                  experience.
                </Text>
                <Text>
                  Whether it is pay by VRM, pay by location, pay on arrival or
                  pay on exit, ANPR integrated payment control, enforcement
                  linked to your back office provider, or a 3D secure payment
                  platform, Twin provides it all. We offer complete flexibility
                  to meet different budgets. Let's work together to make parking
                  easier, more efficient, and customer-friendly.
                </Text>
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="right">
                <Image src={TwinTerminalImage} alt="Twin Terminal" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      <SectionTwo>
        <Container>
          <Row>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="right">
                <Heading>Future Integration with EV Charging</Heading>
                <Text>
                  Future-proof your car park with Twin terminals. The Twin
                  terminals allow you to easily integrate charging points into
                  your car park whenever you're ready to add. They easily link
                  to any open protocol charging points, giving you the
                  flexibility and control to grow your charging capabilities as
                  demand increases.
                </Text>
                <Text>
                  For more information on Twin's innovative payment terminals,
                  please visit their website at{" "}
                  <a href="http://www.twinbank.co.uk">www.twinbank.co.uk</a>.
                </Text>
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="right">
                <ImageTwo src={TwinChargeImage} alt="Twin Charge" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </SectionTwo>
      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
};

export default CardPaymentPage;

import React from 'react';
import { Navbar, Container, Nav, NavItem } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import logo from './assets/images/logo.png';


const Logo = styled.img`
    height: 200px;
    width: auto;
`;

const StyledLink = styled(Link)`
    position: relative;
    color: black;
    margin-right: 50px;
    text-decoration: none;
    font-size: 18px;

    ::before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: blue;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    &:hover::before {
        visibility: visible;
        width: 100%;
    }
`;

const StyledAnchor = styled.a`
    position: relative;
    color: black;
    margin-right: 50px;
    text-decoration: none;
    font-size: 18px;

    ::before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: blue;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    &:hover::before {
        visibility: visible;
        width: 100%;
    }
`;

function NavigationBar() {
    return (
        <Navbar bg="light" expand="lg" fixed="top">
            <Container fluid>
                <Navbar.Brand as={RouterLink} to="/">
                    <Logo src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="mx-auto">
                    <Nav>
                        <NavItem>
                            <StyledLink as={RouterLink} to="/">
                                Home
                            </StyledLink>
                        </NavItem>
                        <NavItem>
                            <StyledLink to="about" spy={true} smooth={true} offset={-70} duration={500}>
                                About
                            </StyledLink>
                        </NavItem>
                        <NavItem>
                            <StyledLink as={RouterLink} to="/Services">
                                Services
                            </StyledLink>
                        </NavItem>
                        <NavItem>
                            <StyledLink as={RouterLink} to="/contact">
                                Contact
                            </StyledLink>
                        </NavItem>
                        <NavItem>
                            <StyledAnchor
                                href="https://retailpark.ec6pay.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Pay Your PCN
                            </StyledAnchor>
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ThankYouContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
`;

const Message = styled.h1`
    font-size: 2.5em;
    color: #333;
`;

const SubMessage = styled.h2`
    font-size: 1.5em;
    color: #666;
    text-align: center;
    max-width: 500px;
`;

const MailIcon = styled.div`
    font-size: 5em;
    color: #333;
    margin-bottom: 1em;
`;

const ThankYou = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 5000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <ThankYouContainer>
            <MailIcon>
                <FontAwesomeIcon icon={faEnvelope} />
            </MailIcon>
            <Message>Thank You!</Message>
            <SubMessage>Your message has been sent successfully. We will get back to you shortly.</SubMessage>
        </ThankYouContainer>
    );
};

export default ThankYou;

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import PayDisplayImg1 from "./assets/images/ANPR2.jpg";
import PayDisplayImg2 from "./assets/images/ANPR2.jpg";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
`;

const SectionPayDisplay = styled(Section)`
  margin-top: 200px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1 rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.2s; 
  &:hover {
    transform: scale(1.05);
  }
`;

function PayAndDisplay() {
    return (
      <div>
        <GlobalStyle />
        <NavbarComponent />
        <SectionPayDisplay>
          <Container>
            <Row>
              <Col md={6}>
                <Fade direction="right">
                  <Heading>Revolutionize Your Parking Experience with Pay and Display Systems</Heading>
                  <Text>
                    Pay and Display systems are the essence of a modern, efficient parking management. They allow users to pay for only the time they require and display the proof of payment on their vehicle's dashboard. This streamlined process improves customer satisfaction and optimizes parking space utilization.
                  </Text>
                </Fade>
              </Col>
              <Col md={6}>
                <Fade direction="right">
                  <Image src={PayDisplayImg1} alt="Pay and Display Systems" />
                </Fade>
              </Col>
            </Row>
          </Container>
        </SectionPayDisplay>
        
        <Section>
          <Container>
            <Row>
              <Col md={6}>
                <Fade direction="left">
                  <Image src={PayDisplayImg2} alt="Pay and Display Systems" />
                </Fade>
              </Col>
              <Col md={6}>
                <Fade direction="left">
                  <Heading>Efficiency and Ease with Pay and Display</Heading>
                  <Text>
                    The user-friendly nature of Pay and Display systems offers a hassle-free parking experience. Users simply choose their duration of stay, make payment, and place the resulting ticket visibly in their vehicle. The transparent process minimizes disputes and can be effortlessly monitored by parking attendants.
                  </Text>
                </Fade>
              </Col>
            </Row>
          </Container>
        </Section>
  
        <Section>
          <Container>
            <Row>
              <Col md={6}>
                <Fade direction="right">
                  <Heading>Discover the Advantages of Pay and Display</Heading>
                  <Text>
                    Pay and Display systems offer a myriad of benefits. They promote efficient use of parking spaces, provide flexibility and convenience to users, and significantly reduce the requirement for manual enforcement. Especially in bustling urban spaces where every inch counts, these systems are instrumental in maximizing parking revenue and user satisfaction.
                  </Text>
                </Fade>
              </Col>
              <Col md={6}>
                <Fade direction="right">
                 
                </Fade>
              </Col>
            </Row>
          </Container>
        </Section>
  
        <Suspense fallback={<div>Loading...</div>}>
          <FooterInsert />
        </Suspense>
      </div>
    );
  }
  
  export default PayAndDisplay;
  
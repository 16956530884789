import React, { lazy, Suspense } from "react";
import NavbarComponent from './Navbar';
import ServiceInsert from './ServicesInsert';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const StyledServiceInsert = styled.div`
  margin-top: 150px;
`;

function Service() {
    return (
        <div>
            <GlobalStyle />
            <NavbarComponent />
            <StyledServiceInsert>
                <ServiceInsert />
            </StyledServiceInsert>
            <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
        </div>
    );
}

export default Service;

import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade } from 'react-awesome-reveal';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import { faCamera, faLock, faTicketAlt, faCreditCard, faTrafficLight, faFileAlt, faChargingStation } from '@fortawesome/free-solid-svg-icons';


const LightSection = styled.section`
    background: #F5F7FA;
    color: #333;
    padding: 120px 0;
    
`;
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;


const TextContainer = styled.div`
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.05);
`;

const Heading = styled.h2`
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
`;

const Text = styled.p`
    font-size: 1 rem;
    line-height: 1.5;
`;

const TextLeft = styled(Text)`
  text-align: left;
`;

const services = [
    {icon: faCamera, name: 'Automatic Number Plate Recognition (ANPR)', path: '/anpr'},
    {icon: faLock, name: 'Access Control Systems', path: '/access_control'},
    {icon: faTicketAlt, name: 'Pay and Display (P&D)', path: '/pay&Display'},
    {icon: faCreditCard, name: 'Card Payment Systems', path: '/card_payment'},
    {icon: faTrafficLight, name: 'Barrier Systems', path: '/Barrier_Systems'},
    {icon: faFileAlt, name: 'Permit Management', path: '/Permit_management'},
    {icon: faChargingStation, name: 'EV Charging', path: '/EV_charging'} // new service
]

const ServiceText = styled(Text)`
  text-align: center;
  margin-top: 10px;
`;

const ServiceSection = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;

    @media (max-width: 1024px) { // Screen width between 768px and 1024px
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) { // Screen width less than 768px
        grid-template-columns: repeat(1, 1fr);
    }
`;


const Service = styled.div`
    background: #ffffff;
    border: 1px solid #ddd;
    padding: 20px;
    width: 400px; // specify your preferred width
    height: 200px; // specify your preferred height
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    transition: box-shadow .3s, transform .3s;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.05);

    &:hover {
        box-shadow: 0px 20px 40px rgba(0,0,0,0.1); 
        transform: translateY(-5px);
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
`;

function ServicesInsert() {
    return (
        <>
            <GlobalStyle />
            <LightSection id="services">
                <Container>
                    <Fade direction="left">
                        <Heading>Our Services</Heading>
                        <TextContainer>
                            <TextLeft>
                                Our service range includes standard Pay and Display (P&D) systems, split ticketing, Automatic 
                                Number Plate Recognition (ANPR), and enforcement measures. No matter the complexity of the 
                                problem at hand, we offer a multitude of services specifically tailored to your distinct needs.
                            </TextLeft>
                            <TextLeft>
                                From the initial consultation to final implementation, our dedicated team adopts a proactive approach. 
                                We liaise seamlessly with architects and local authorities, ensuring that every detail of your project 
                                is meticulously managed. We go beyond traditional services - clearing sites, demolition when necessary, 
                                groundwork, fencing, signage creation, and ticket machine installation are all part of our comprehensive 
                                offerings.
                            </TextLeft>
                        </TextContainer>
                        <Heading>Our extensive range of services includes:</Heading>
                        <ServiceSection>
                            {services.map((service, idx) => (
                                <StyledLink to={service.path} key={idx}>
                                    <Fade direction="up">
                                        <Service>
                                            <FontAwesomeIcon icon={service.icon} size="4x" color="#333" />
                                            <ServiceText>{service.name}</ServiceText>
                                        </Service>
                                    </Fade>
                                </StyledLink>
                            ))}
                        </ServiceSection>
                        <br></br>
                        <br></br>
                        <TextContainer>
                            <TextLeft>
                                At Retail Park Limited, we are dedicated to creating parking solutions that are as efficient as 
                                they are effective, ensuring seamless operations and satisfied customers. Connect with us today to 
                                discover how we can revolutionize your parking management system.
                            </TextLeft>
                        </TextContainer>
                    </Fade>
                </Container>
            </LightSection>
        </>
    );
}

export default ServicesInsert;

import React, { lazy, Suspense } from "react";
import NavbarComponent from "./Navbar";
import styled, { createGlobalStyle } from "styled-components";
import { Container } from "react-bootstrap";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const ParkingTermsContainer = styled(Container)`
  margin-top: 300px;
  margin-bottom: 100px;
`;

const ParkingTerms = () => {
    return (
      <div>
        <GlobalStyle />
        <NavbarComponent />
  
        <ParkingTermsContainer>
          <h1>Parking Terms and Conditions</h1>
  
          <h2>Location Specific Terms</h2>
          <p>
            Please note that Retail Park Ltd operates numerous parking facilities, each with its own specific set of terms and conditions. These terms are displayed prominently on signage around each site. We strongly advise all users of our parking facilities to familiarize themselves with these terms and conditions prior to parking.
          </p>
  
          <h2>Liability</h2>
          <p>
            Whilst we strive to keep our parking facilities safe and secure, please be aware that vehicles and their contents are left at the owner's risk. Retail Park Ltd does not accept liability for any accidents, damages, or losses that may occur whilst your vehicle is parked in our facilities, regardless of how they may be caused.
          </p>
  
          <h2>Queries and Complaints</h2>
          <p>
            If you have any questions or concerns related to our parking facilities, please contact us at enquiries@retailparkuk.co.uk. We appreciate your feedback and are here to help.
          </p>
          
        </ParkingTermsContainer>
  
        <Suspense fallback={<div>Loading...</div>}>
          <FooterInsert />
        </Suspense>
      </div>
    );
  };
  
  export default ParkingTerms;
  

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import AccessControl1 from "./assets/images/access1.jpg";
import AccessControl2 from "./assets/images/access2.webp";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const FirstSection = styled(Section)`
  padding-top: 240px; // Adjust this value according to your Navbar's height
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

function AccessControl() {
  return (
    <div>
      <Helmet>
        <title>Access Control - Retail Park Limited</title>
        <meta
          name="description"
          content="At Retail Park Limited, we offer cutting-edge Access Control solutions, tailored specifically to enhance the security and efficiency of car parking environments."
        />
        <meta
          name="keywords"
          content="Access Control, Retail Park, car parking, ticketed entry systems, virtual access systems, barriers, shutters, pedestrian doors"
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />

      <FirstSection>
        <Container>
          <Row>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="left">
                <Image src={AccessControl1} alt="Access Control" />
              </Fade>
            </Col>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="right">
                <Heading>Access Control</Heading>
                <Text>
                  At Retail Park Limited, we offer cutting-edge Access Control
                  solutions, tailored specifically to enhance the security and
                  efficiency of car parking environments. Whether your facility
                  is a multi-storey building or an open car park, our pioneering
                  Access Control systems provide a holistic solution. From
                  ticketed entry systems to entirely virtual access systems for
                  barriers, shutters, and pedestrian doors, we've got you
                  covered.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </FirstSection>

      <Section>
        <Container>
          <Row>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="left">
                <Heading>Integration Capabilities</Heading>
                <Text>
                  We pride ourselves on our integration capabilities, pairing
                  with most global barrier and fast shutter providers. Our
                  seasoned team, armed with our innovative software, ensures a
                  smooth installation process, providing expert guidance from
                  the initial phase to completion. This meticulous approach
                  allows us to craft the perfect Access Control suite that
                  complements your environment.
                </Text>
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="right">
                <Image src={AccessControl2} alt="Access Control Integration" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>
      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
      {/* Additional sections as per your requirements */}
    </div>
  );
}

export default AccessControl;

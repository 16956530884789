import React, { lazy, Suspense } from "react";
import NavbarComponent from "./Navbar";
import styled, { createGlobalStyle } from "styled-components";
import { Container } from "react-bootstrap";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const AppealsContainer = styled(Container)`
  margin: 100px auto;
  margin-top: 300px;
  max-width: 1200px;
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
`;

const Header = styled.h1`
  color: #333;
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const SubHeader = styled.h2`
  color: #333;
  font-size: 2em;
  margin: 20px 0;
`;

const Text = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Signature = styled.p`
  color: #666;
  font-size: 1.2em;
  margin-top: 40px;
  text-align: right;
`;

const AppealsPolicyPage = () => {
  return (
    <div>
      <GlobalStyle />
      <NavbarComponent />

      <AppealsContainer>
        <Header>Appeals Policy</Header>
        
        <SubHeader>1. Submission of Appeal</SubHeader>
        <Text>
          Appeals need to be made in writing within 28 days of the offence. Please use the following contact details:
        </Text>
        <Text>
          <strong>Email:</strong> <a href="mailto:pcn@retailparkuk.co.uk">pcn@retailparkuk.co.uk</a><br />
          <strong>Address for letters:</strong> Retail Park Ltd, PO Box 6142, Sheffield, S2 9JA<br />
          Registered in England & Wales | Company No: 12782256
        </Text>
        <Text>
          Upon receipt of your email regarding the Parking Charge Notice (PCN) you've received, it will be considered as a formal appeal. To properly process your appeal, please ensure it contains either the PCN reference number or the vehicle registration mark.
        </Text>

        <SubHeader>2. Appeal Investigation</SubHeader>
        <Text>
          Your appeal will be put on hold until fully investigated. A response will be sent from the Appeals Team after the completion of the investigation. Please allow 21 days for the team to respond and ensure to check your junk or spam mail for a response.
        </Text>

        <SubHeader>3. Post-decision Appeal</SubHeader>
        <Text>
          If you've already received a response and a decision has been made by the Appeals Team, please be advised that a further appeal will not be accepted nor put on hold. If you are dissatisfied with the decision, you can appeal further via POPLA.
        </Text>
        
        <SubHeader>4. Appeal via POPLA</SubHeader>
        <Text>
          Appeals to POPLA must be submitted within 21 days from the date of our decision's rejection. Visit <a href="https://www.popla.co.uk/start-an-appeal">https://www.popla.co.uk/start-an-appeal</a> for full details on how to lodge your appeal with POPLA.
        </Text>

        <Signature>
          Kind regards, <br/> Retail Park Limited 
        </Signature>
      </AppealsContainer>
      
      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
};

export default AppealsPolicyPage;

import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import BarrierImg1 from "./assets/images/barrier1.jpg";
import BarrierImg2 from "./assets/images/barrier2.PNG";
import BarrierImg3 from "./assets/images/system-integration.jpeg";
import BarrierImg4 from "./assets/images/addvalue.png";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const SectionBarrier = styled(Section)`
  margin-top: 200px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1 rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const SmallImage = styled(Image)`
  max-width: 300px;
  margin-left: 100px;
`;

function BarrierSystems() {
  return (
    <div>
      <Helmet>
        <title>Barrier Systems - Retail Park Limited</title>
        <meta
          name="description"
          content="Discover how barrier systems can enhance your car park, offering security, space utilisation, and a streamlined parking experience."
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />
      <SectionBarrier>
        <Container>
          <Row>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="right">
                <Heading>Enhance Your Car Park with Barrier Systems</Heading>
                <Text>
                  Barrier systems, a cornerstone of efficient and secure car
                  parking solutions, offer much more than just facilitating
                  vehicle access and fortifying security measures. These
                  sophisticated installations, when integrated with existing
                  structures, can transform a rudimentary parking space into a
                  smooth and efficient parking experience for customers. They
                  not only streamline the flow of traffic, minimizing
                  bottlenecks and congestion, but also improve space
                  utilisation, effectively managing the often limited parking
                  area. Additionally, the advanced technology that powers these
                  systems allows for easy maintenance and quick troubleshooting,
                  further augmenting the value they bring to your parking
                  management operations.
                </Text>
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="right">
                <Image src={BarrierImg1} alt="Barrier Systems" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </SectionBarrier>

      {/* Section about how barrier systems work */}
      <Section>
        <Container>
          <Row>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="left">
                <Image src={BarrierImg2} alt="Barrier Systems" />
              </Fade>
            </Col>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="left">
                <Heading>How Barrier Systems Work</Heading>
                <Text>
                  In essence, barrier systems are designed to control and
                  regulate the entry and exit points of a car park. But beneath
                  this fundamental operation lies a sophisticated network of
                  technological components that enable its efficient
                  functioning. Depending on your requirements, these systems can
                  be either manually operated or automated. Automated barrier
                  systems leverage cutting-edge technologies such as Automatic
                  Number Plate Recognition (ANPR), and can seamlessly integrate
                  with other parking systems. This integration can lead to the
                  automation of several manual tasks such as car tracking, toll
                  collection, and overall parking management. It's this
                  intelligence and flexibility that elevates automated barrier
                  systems from being just an access control tool to a
                  comprehensive parking management solution.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Section about the benefits of barrier systems */}
      <Section>
        <Container>
          <Row>
            <Col md={6}>
              <Fade direction="right">
                <Heading>Benefits of Barrier Systems</Heading>
                <Text>
                  Barrier systems offer numerous benefits, such as increased
                  security by preventing unauthorized access, improved
                  management of parking spaces, and enhanced customer experience
                  through easy entry and exit. They can also contribute to the
                  revenue generation of a car park by preventing parking space
                  misuse.
                </Text>
              </Fade>
            </Col>

            <Col md={6}>
              <Fade direction="right">
                <SmallImage src={BarrierImg4} alt="Barrier Systems" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Section about integrating barrier systems with existing barriers */}
      <Section>
        <Container>
          <Row>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="left">
                <Image src={BarrierImg3} alt="Barrier Systems" />
              </Fade>
            </Col>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="left">
                <Heading>Integrating Barrier Systems</Heading>
                <Text>
                  We specialize in flawlessly integrating new barrier systems
                  with your existing structures, amplifying their performance
                  and functionality. Whether catering to a public car park or an
                  exclusive business facility, our complimentary site survey
                  assesses your unique requirements, ensuring the provision of
                  tailored solutions that resonate with your needs.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
}

export default BarrierSystems;

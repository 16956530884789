import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import EVImg1 from "./assets/images/EVcharge.jpg";
import EVImg2 from "./assets/images/Evcharge2.jpeg";
import { Helmet } from "react-helmet"; // Import the Helmet component

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const SectionEV = styled(Section)`
  margin-top: 200px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1 rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

function EVCharging() {
  return (
    <div>
      <Helmet>
        <title>Retail Park EV Charging Solutions | Twin Charge</title>
        <meta
          name="description"
          content="Learn about our specialized EV charging solutions for retail parks. Discover how Twin Charge can assist you in providing efficient and convenient EV charging solutions for your retail park."
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />
      <SectionEV>
        <Container>
          <Row>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="right">
                <Heading>Transform Your EV Charging with Twin Charge</Heading>
                <Text>
                  Twin Charge offers a reliable platform for the management and
                  monetisation of your EV charging points. Following a
                  comprehensive EV site survey, we develop bespoke charging
                  solutions tailored to your specific needs. Our platform
                  integrates seamlessly with existing EV charging equipment and
                  can connect to an unlimited number of EV charge points via our
                  unique Twin payment terminals and TwinPay app. Our open
                  protocol equipment interfaces with most global manufacturers,
                  providing the fastest route to multiple EV charging points
                  ranging from 3.5kW to 300kW fast chargers. In addition to
                  offering installation services, we also provide guidance on
                  different types of EV charging points and the benefits of
                  various EV solutions for your unique environment.
                </Text>
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="right">
                <Image src={EVImg1} alt="EV Charging" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </SectionEV>

      {/* Section about how EV charging works */}
      <Section>
        <Container>
          <Row>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="left">
                <Image src={EVImg2} alt="EV Charging" />
              </Fade>
            </Col>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="left">
                <Heading>Professional EV Site Surveys</Heading>
                <Text>
                  Implementing EV charging in a commercial setting, the
                  workplace, community spaces, public areas, or even at home can
                  be a complex process without adequate knowledge of the EV
                  market and potential pitfalls. That's where Twin Charge's
                  professional EV site surveys come into play. We conduct a
                  comprehensive survey, either via a simple desktop review or a
                  full site visit. Following the survey, we provide you with all
                  the necessary information, potential pricing, and an unbiased
                  view thanks to our Open Charge Point Protocol system (OCPP).
                  Whether you need advice on the number of EV chargers required,
                  installation, how to monetise your EV charge offering, or just
                  want some advice on where to start, we can help.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Section about the benefits of EV charging */}
      <Section>
        <Container>
          <Row>
            <Col md={6}>
              <Fade direction="right">
                <Heading>EV Charging: An Investment</Heading>
                <Text>
                  Twin Charge offers a unique EV cash calculator tool that
                  enables you to determine potential earnings from your EV
                  charging points. Whether your chargers are already installed
                  or planned for future installation, our calculator can
                  demonstrate potential earnings. Utilising our TwinPay app or
                  Twin payment terminals to facilitate transactions can help you
                  recoup your capital investment.
                </Text>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade direction="right"></Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
}

export default EVCharging;

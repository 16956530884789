import React, { lazy, Suspense } from "react";
import { Container, Button, Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled, { createGlobalStyle } from "styled-components";
import banner1 from "./assets/images/banner2.jpeg";
import banner2 from "./assets/images/retail.jpg";
import about_us from "./assets/images/about_us.png";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import NavbarComponent from "./Navbar";

const banners = [banner1, banner2];

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
  }
`;

const Section = styled.section`
  padding: 100px 0;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #007bff;
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
`;

const StyledButton = styled(Button)`
  border-radius: 25px;
  padding: 10px 25px;
  border: none;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const TextLeft = styled(Text)`
  text-align: left;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 800px;
  object-fit: cover;
`;

const AboutImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: 50px;
`;

const ServiceInsert = lazy(() => import("./ServicesInsert"));
const FooterInsert = lazy(() => import("./FooterInsert"));

function Home() {
  return (
    <div>
      <GlobalStyle />
      <NavbarComponent />
      <Carousel fade interval={5000}>
        {banners.map((banner, idx) => (
          <Carousel.Item key={idx}>
            <CarouselImage
              className="d-block w-100"
              src={banner}
              alt={`Slide ${idx + 1}`}
              loading="lazy" // Add lazy loading attribute
            />
            <Carousel.Caption>
              <Container>
                <Fade direction="left" duration={5000}>
                  <Heading
                    style={{
                      color: "white",
                      textShadow:
                        "2px 2px 8px rgba(0, 0, 0, 0.5), 0 0 20px #000080",
                      fontSize: "3rem",
                    }}
                  >
                    {idx === 0
                      ? "Welcome to Retail Park Limited"
                      : "Welcome to Retail Park Limited"}
                  </Heading>
                  <Text
                    style={{
                      color: "white",
                      textShadow:
                        "2px 2px 8px rgba(0, 0, 0, 0.5), 0 0 20px #000080",
                      fontSize: "1.5rem",
                    }}
                  >
                    {idx === 0
                      ? "Your specialist in retail parking management."
                      : "Your specialist in retail parking management."}
                  </Text>
                  <StyledButton variant="primary">Learn More</StyledButton>
                </Fade>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <Section id="about">
        <Container>
          <Fade direction="right">
            <Row>
              <Col xs={12} md={6}>
                <div>
                  <Heading>About Us</Heading>
                  <TextLeft>
                    At Retail Park Limited, we pride ourselves on our
                    proficiency as specialised parking management experts
                    tailored specifically for the retail industry. We deliver
                    comprehensive car park management solutions that are bespoke
                    to each client's unique needs, exhibiting not only our
                    versatility but also our dedication to bespoke solutions.
                  </TextLeft>
                  <TextLeft>
                    With swift problem identification and solution-driven
                    strategies, we can effectively tackle a myriad of parking
                    issues. Whether it's residential developments or commercial
                    properties, our team is well-equipped to provide pragmatic
                    proposals rapidly and professionally.
                  </TextLeft>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <AboutImage src={about_us} alt="About Image" />
              </Col>
            </Row>
          </Fade>
        </Container>
      </Section>
      <Suspense fallback={<div>Loading...</div>}>
        <ServiceInsert />
      </Suspense>
      <Section id="contact">
        <Container>
          <Fade direction="right">
            <Heading>Contact Us</Heading>
            <Text>
              To learn more about our services and how we can assist your retail
              parking management needs, get in touch with us.
            </Text>

            <Link to="/contact">
              <StyledButton variant="primary">Contact Us</StyledButton>
            </Link>
          </Fade>
        </Container>
      </Section>
      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
}

export default Home;

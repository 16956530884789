import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import ANPR1 from "./assets/images/what-is-anpr.webp";
import ANPR2 from "./assets/images/ANPR2.jpg";
import ANPR3 from "./assets/images/data_stats.jpg";
import ANPR4 from "./assets/images/improve.png";
import { Helmet } from "react-helmet"; 

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
`;

const SectionANPR = styled(Section)`
  margin-top: 200px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const Image = styled.img`
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.2s; 
  &:hover {
    transform: scale(1.05);
  }
`;

function ANPR() {
  return (
    <div>
       <Helmet>
        <title>ANPR - Retail Park Limited</title>
        <meta
          name="description"
          content="Learn about ANPR technology at Retail Park Limited. Discover how we use ANPR for efficient car park management and vehicle tracking."
        />
        <meta
          name="keywords"
          content="ANPR, Automatic Number Plate Recognition, car park management, vehicle tracking, Retail Park Limited"
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />
      <SectionANPR>
        <Container>
          <Row>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="right">
                <Heading>Automatic Number Plate Recognition (ANPR)</Heading>
                <Text>
                  Automatic Number Plate Recognition (ANPR) technology is used
                  to help detect and deter motor vehicle crime. It can also be a
                  useful tool for other applications, such as car park
                  management.
                </Text>
                <Text>
                  At Retail Park Limited, our ANPR solutions are designed to be
                  efficient and easy to use. With ANPR cameras in place, you can
                  track vehicle movements, manage parking enforcement, and even
                  gather useful data for business intelligence.
                </Text>
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="right">
                <Image src={ANPR1} alt="ANPR" />
              </Fade>
            </Col>
          </Row>
        </Container>
      </SectionANPR>

      {/* Section about how ANPR works */}
      <Section>
        <Container>
          <Row>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="left">
                <Image src={ANPR2} alt="ANPR2" />
              </Fade>
            </Col>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="left">
                <Heading>How ANPR Works</Heading>
                <Text>
                  ANPR operates using high-resolution cameras coupled with
                  advanced machine learning algorithms. The system captures an
                  image of the vehicle's license plate, which is then converted
                  into text data. This data is subsequently compared with a
                  pre-existing database for various purposes, including vehicle
                  tracking, toll collection, and parking management.
                </Text>
                
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Section about improving car parks */}
      <Section>
        <Container>
          <Row>
          
            <Col md={6}>
              <Fade direction="right">
                <Heading>Improving Car Parks with ANPR</Heading>
                <Text>
                  ANPR technology plays a significant role in enhancing car park
                  management. By automating the entry and exit process, ANPR
                  provides seamless and contactless parking experiences for
                  customers. The system can effectively track the time a vehicle
                  enters and leaves the car park, eliminating the need for paper
                  tickets and manual timekeeping. This not only reduces
                  operational costs but also increases the efficiency and
                  capacity utilization of the car park. Additionally, ANPR can
                  be integrated with payment systems to automatically calculate
                  parking fees based on the length of stay, providing a more
                  convenient payment process for customers. By deterring
                  unauthorized parking and ensuring fair use, ANPR ultimately
                  leads to improved service and increased customer satisfaction.
                </Text>
                
              </Fade>
            </Col>
            {/* Image Column */}
            <Col md={6}>
            <Image src={ANPR4} alt="ANPR3" />
            
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Section about collecting data on vehicle movements */}
      <Section>
        <Container>
          <Row>
            {/* Image Column */}
            <Col md={6}>
              <Fade direction="left">
                <Image src={ANPR3} alt="ANPR3" />
              </Fade>
            </Col>
            {/* Text Column */}
            <Col md={6}>
              <Fade direction="left">
                <Heading>Data Collection & Reporting</Heading>
                <Text>
                  ANPR technology is a powerful tool for collecting and
                  analyzing data on vehicle movements. The system automatically
                  records the date, time, and license plate number of each
                  vehicle that passes through, generating a wealth of data that
                  can be used for various purposes. This data can provide
                  valuable insights into traffic patterns, peak times, and usage
                  rates, helping to inform strategic decisions such as pricing
                  adjustments and capacity planning. Furthermore, ANPR data can
                  assist in investigating incidents and identifying trends over
                  time. With robust reporting features, ANPR allows for easy
                  monitoring and analysis of this data, offering a comprehensive
                  view of car park operations.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>
      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
}

export default ANPR;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import Home from "./pages/home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Testimonials from "./pages/Testimonials";
import ThankYou from "./pages/thankyou";
import ANPR from "./pages/ANPR";
import AccessControl from "./pages/Access_control";
import ComplaintsPolicy from "./pages/Complaints_policy";
import PrivacyPolicy from "./pages/Privacy";
import CookiesPolicy from "./pages/cookies_policy";
import AppealsProcessPage from "./pages/appeals_process";
import CardPaymentPage from "./pages/card_payment";
import PermitManagement from "./pages/Permit_Management";
import BarrierSystems from "./pages/Barrier_Systems";
import PayAndDisplay from "./pages/Pay&Display";
import EVCharging from "./pages/EV_charging";
import ParkingTerms from "./pages/parking_terms";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

function AnimatedRoutes() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes location={location} key={location.pathname}>
      <Route
        path="/"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Home />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/about"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <About />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/services"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Services />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/ANPR"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <ANPR />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/contact"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Contact />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/testimonials"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <Testimonials />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/thankyou"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <ThankYou />
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/access_control"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <AccessControl />
            </motion.div>
          </AnimatePresence>
        }
      />

      <Route
        path="/complaints_policy"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <ComplaintsPolicy />
            </motion.div>
          </AnimatePresence>
        }
      />

      <Route
        path="/privacy"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <PrivacyPolicy />
            </motion.div>
          </AnimatePresence>
        }
      />


<Route
        path="/cookies_policy"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <CookiesPolicy />
            </motion.div>
          </AnimatePresence>
        }
      />


<Route
        path="/appeals_process"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <AppealsProcessPage />
            </motion.div>
          </AnimatePresence>
        }
      />


<Route
        path="/Card_payment"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <CardPaymentPage />
            </motion.div>
          </AnimatePresence>
        }
      />

<Route
        path="/Permit_management"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <PermitManagement />
            </motion.div>
          </AnimatePresence>
        }
      />

<Route
        path="/Barrier_Systems"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <BarrierSystems />
            </motion.div>
          </AnimatePresence>
        }
      />


<Route
        path="/Pay&Display"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <PayAndDisplay />
            </motion.div>
          </AnimatePresence>
        }
      />


<Route
        path="/EV_charging"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <EVCharging/>
            </motion.div>
          </AnimatePresence>
        }
      />
      <Route
        path="/parking_terms"
        element={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              <ParkingTerms/>
            </motion.div>
          </AnimatePresence>
        }
      />
    </Routes>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;

import React, { lazy, Suspense } from 'react';
import NavbarComponent from './Navbar';
import styled, { createGlobalStyle } from 'styled-components';
import { Container } from 'react-bootstrap';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

  body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  }
`;

const FooterInsert = lazy(() => import('./FooterInsert'));

const PrivacyCookiesContainer = styled(Container)`
  margin-top: 300px;
  margin-bottom: 100px;
`;

const PrivacyCookies_policy = () => {
  return (
    <div>
      <GlobalStyle />
      <NavbarComponent />
      
      <PrivacyCookiesContainer>
        <h1>Privacy and Cookies Policy</h1>

        <h2>1. Privacy Policy</h2>
        <p>Retail Park Ltd ("we" or "us") are committed to protecting and respecting your privacy. This policy explains the types of personal information we collect, how we use that information, who we share it with, how we protect that information, and your legal rights in relation to your information. We are committed to abiding by this policy, as well as the requirements of applicable laws, in the operation of our business.</p>

        <h2>2. Cookies Policy</h2>
        <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>

        <h2>3. Consent</h2>
        <p>By using our website, you consent to our use of cookies as described in this policy. You can withdraw your consent at any time, but if you choose not to consent, please be aware that parts of our website may not function properly or be accessible.</p>

        <h2>4. Changes to our Privacy and Cookies Policy</h2>
        <p>Any changes we may make to our privacy and cookies policy in the future will be posted on this page. Please check back frequently to see any updates or changes to our privacy and cookies policy.</p>

        <h2>5. Contact</h2>
        <p>Questions, comments and requests regarding this privacy and cookies policy are welcomed and should be addressed to enquiries@retailparkuk.co.uk.</p>
      </PrivacyCookiesContainer>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
};

export default PrivacyCookies_policy;

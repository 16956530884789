import React, { lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import NavbarComponent from "./Navbar";
import { Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faParking,
  faBuilding,
  faMapMarkerAlt,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
  body {
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-decoration: none;
    background-color: #f2f2f2;
  }
`;

const FooterInsert = lazy(() => import("./FooterInsert"));

const Section = styled.section`
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const SectionPermit = styled(Section)`
  margin-top: 200px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
  border-bottom: 3px solid #007bff;
`;

const Text = styled.p`
  font-size: 1 rem;
  line-height: 1.8;
  text-align: justify;
  color: #666;
`;

const IconWrapper = styled.div`
  font-size: 8rem;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function PermitManagement() {
  return (
    <div>
      <Helmet>
        <title>Retail Park Permit Management | Retail Park Limited</title>
        <meta
          name="description"
          content="Efficiently manage permits across your sites with Retail Park Limited's online platform. Ideal for public car parks, businesses requiring access control, and a wide range of other applications. Streamline your operations today."
        />
      </Helmet>
      <GlobalStyle />
      <NavbarComponent />
      <SectionPermit>
        <Container>
          <Row>
            <Col md={6}>
              <Fade direction="right">
                <Heading>Permit Management</Heading>
                <Text>
                  At Retail Park Limited, our online platform offers an easy way
                  for you to manage permits across your sites. With our system,
                  users can take control and manage permits efficiently and
                  conveniently. Our platform provides an easy-to-use interface
                  for permit creation, allocation, and tracking, significantly
                  reducing manual effort and increasing productivity.
                </Text>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade direction="right">
                <IconWrapper>
                  <FontAwesomeIcon icon={faTasks} />
                </IconWrapper>
              </Fade>
            </Col>
          </Row>
        </Container>
      </SectionPermit>

      <Section>
        <Container>
          <Row>
            <Col md={6}>
              <Fade direction="left">
                <IconWrapper>
                  <FontAwesomeIcon icon={faParking} />
                </IconWrapper>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade direction="left">
                <Heading>Public Car Parks</Heading>
                <Text>
                  Our system is ideal for managing permits in public car parks.
                  With our technology, users can easily control access and
                  ensure that only authorized vehicles are allowed in the car
                  park. Additionally, our platform can help in maximizing
                  parking efficiency, minimizing fraudulent activities, and
                  improving the overall user experience through smooth and
                  streamlined parking operations.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col md={6}>
              <Fade direction="right">
                <Heading>Access Control for Businesses</Heading>
                <Text>
                  For businesses, our permit management system can also be used
                  for access control. This is perfect for businesses that need
                  to manage access to their premises, whether it's a small
                  office building or a large industrial site. By implementing a
                  robust permit management system, businesses can ensure
                  security and efficiency in their operations. With customizable
                  permit categories and approval workflows, businesses can have
                  granular control over their premises access, ensuring security
                  and operational efficiency.
                </Text>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade direction="right">
                <IconWrapper>
                  <FontAwesomeIcon icon={faBuilding} />
                </IconWrapper>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col md={6}>
              <Fade direction="left">
                <IconWrapper>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </IconWrapper>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade direction="left">
                <Heading>Permit Management for Various Purposes</Heading>
                <Text>
                  Our permit management system is versatile and can be used for
                  a variety of purposes. Whether it's for event management,
                  residential parking, or even for managing permits for a large
                  event, our system can handle it all. With our system, you can
                  issue permits, manage renewals, and even handle payments,
                  making it a comprehensive solution for all your permit
                  management needs. Moreover, our platform provides
                  comprehensive reporting capabilities for easy monitoring and
                  tracking of permit status and usage.
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Section>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterInsert />
      </Suspense>
    </div>
  );
}

export default PermitManagement;
